<script setup lang="ts">
const currentYear = new Date().getFullYear()
</script>

<template>
    <footer class="p-4 md:p-10 py-0 md:py-0">
        <div
            class="w-full flex flex-col lg:flex-row shadow-md justify-between p-4 md:p-12 bg-cool-700 dark:bg-cool-900 rounded-3xl md:px-20 gap-8 text-white">
            <div class="flex flex-col gap-4">
                <div class="text-4xl font-yellowtail">Wankul Market <span class="text-xs font-normal">v1.0.0</span>
                </div>
                <div class="flex gap-4">
                    <ULink to="https://x.com/WankulMarket" target="_blank">
                        <UIcon class="text-2xl" name="i-ph-x-logo" dynamic />
                    </ULink>
                    <ULink to="https://fr.linkedin.com/in/quentin-deme" target="_blank">
                        <UIcon class="text-2xl" name="i-ph-linkedin-logo" dynamic />
                    </ULink>
                </div>
            </div>
            <div class="flex flex-col">
                <div class="text-xl font-medium flex items-center gap-2 mb-4">
                    <UIcon name="i-heroicons-information-circle" />Aide et informations
                </div>
                <ULink to="https://wankulmarket.sleekplan.app" target="_blank" active-class="text-grey"
                    inactive-class="text-gray-300 dark:text-gray-400 dark:hover:text-gray-200">
                    Feedback
                </ULink>
                <ULink to="https://wankulmarket.sleekplan.app/changelog" target="_blank" active-class="text-grey"
                    inactive-class="text-gray-300 dark:text-gray-400 dark:hover:text-gray-200">
                    Changelog
                </ULink>
            </div>
            <div class="flex flex-col">
                <div class="text-xl font-medium flex items-center gap-2 mb-4">
                    <UIcon name="i-heroicons-command-line" />À propos du développeur
                </div>
                <ULink to="https://fr.linkedin.com/in/quentin-deme" target="_blank" active-class="text-grey"
                    inactive-class="text-gray-300 dark:text-gray-400 dark:hover:text-gray-200">
                    LinkedIn
                </ULink>
            </div>
            <div class="flex flex-col">
                <div class="text-xl font-medium flex items-center gap-2 mb-4">
                    <UIcon name="i-heroicons-arrow-top-right-on-square" />Liens utiles
                </div>
                <ULink to="https://wankul.fr/" target="_blank" active-class="text-grey"
                    inactive-class="text-gray-300 dark:text-gray-400 dark:hover:text-gray-200">
                    Wankul.fr
                </ULink>
                <ULink to="https://wankil.fr/" target="_blank" active-class="text-grey"
                    inactive-class="text-gray-300 dark:text-gray-400 dark:hover:text-gray-200">
                    Wankil.fr
                </ULink>
                <ULink to="https://vinted.fr/" target="_blank" active-class="text-grey"
                    inactive-class="text-gray-300 dark:text-gray-400 dark:hover:text-gray-200">
                    Vinted.fr
                </ULink>
                <ULink to="https://uptimekuma.qlde.fr/status/wankulmarket" target="_blank" active-class="text-grey"
                    inactive-class="text-gray-300 dark:text-gray-400 dark:hover:text-gray-200">
                    État de l'application
                </ULink>
            </div>
        </div>
        <div class="flex items-center px-4 py-2 text-xs">
            Copyright
            <UIcon name="i-ph-copyright" dynamic />
            {{ currentYear }} Quentin DEMÉ. All rights reserved.
        </div>
    </footer>
</template>